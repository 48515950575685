import React from "react";
import momentZone from "moment-timezone";
import md5 from "md5";
import axios from "axios";
import moment from "moment";
import { decode, encode } from "base-64";
import { Avatar, Badge, Col, Row, Skeleton } from "antd";
import ReactPaginate from "react-paginate";
import "../actor.css";
import { Link } from "react-router-dom";
import ReactGA from "react-ga4";

ReactGA.initialize("G-883ML6DGJC");
interface props { }
interface stateType {
    width: number;
    total: number;
    actorList: any;
    signGet: string;
    currentPage: any;
}

class Actor extends React.PureComponent<props, stateType> {
    constructor(props: props | Readonly<props>) {
        super(props);
        console.log("running");
        var date = md5(
            momentZone.tz("Asia/Shanghai").format("YYYY-MM-DD-HH") + "sign91"
        );
        this.state = {
            width: window.innerWidth,
            total: 0,
            actorList: [],
            signGet: date,
            currentPage: 1,
        };
    }

    keyHash = (key: string) => {
        if (key == "400" || key.length == 0) {
            return "";
        } else {
            var date1 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
                .add(1, "hours")
                .format("YYYY-MM-DD-HH");
            var date2 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")
                .subtract(1, "hours")
                .format("YYYY-MM-DD-HH");

            var add = encode(md5(date1 + "sign91")).replace("=", "");
            var subtract = encode(md5(date2 + "sign91")).replace("=", "");

            var length1 = key.length;
            var length2 = length1 - 20;
            var content1 = key.substring(length2, length1);
            var content2 = key.substring(0, length2);
            ///////////////////////////////////////////
            var keys = encode(this.state.signGet).replace("=", "");
            let reg = new RegExp("END", "g"); //g代表全部
            var keyHashFirst = content1.replace(reg, "");
            var keyHash = content2 + keyHashFirst;
            var keyResult = keyHash.replace(keys, "");
            var keyResult1 = keyResult.replace(subtract, "");
            var keyResult2 = keyResult1.replace(add, "");
            return decode(keyResult2.toString());
        }
    };

    axiosGet() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        const _this = this; //先存一下this，以防使用箭头函数this会指向我们不希望它所指向的对象。
        _this.setState({
            actorList: [],
        });
        axios
            .get(
                window.apiUrl +
                "/Application/actor?type=actorList&orderBy=default&page=" +
                this.state.currentPage +
                "&lang=zh&sign=" +
                this.state.signGet
                , {
                    headers: {
                        'Content-Type': 'application/json; charset=utf-8'
                    }
                })
            .then((response: { data: any }) => {
                var decode5 = _this.keyHash(response.data);
                let reg = new RegExp("\\+", "g"); //g代表全部
                let newMsg = decode5.replace(reg, "  ");
                var result = JSON.parse(newMsg);
                const data = result;
                console.log(data);
                _this.setState({
                    actorList: data,
                });

                if (this.state.total == 0 && this.state.actorList != null) {
                    _this.setState({
                        total: parseInt(data.total[0].num) / 24,
                    });
                }
            })
            .catch(function (error: any) {
                console.log(error);
            });
    }

    componentDidMount() {
        document.title = "AV女优 - " + window.title;
        setTimeout(() => {
            ReactGA.send({
                hitType: "pageview",
                page: "/actor?type=all",
                title: "AV女优 -" + window.title,
            });
        }, 300);
        this.axiosGet();
    }

    // destroy player on unmount
    componentWillUnmount() { }

    handlePageClick = (event: { selected: number }) => {
        this.setState({
            currentPage: event.selected + 1,
        });
        setTimeout(() => {
            this.axiosGet();
        }, 500);
    };

    render() {
        const isMobile = this.state.width <= 820;

        function ListItems(props: { items: any }) {
            const items = props.items;
            const listItem = items.map(
                (
                    item: {
                        actor: string;
                        img: string;
                        num: number;
                        Id: React.Key | null | undefined;
                    },
                    i: any
                ) => (
                    <Col xs={8} sm={8} md={8} lg={3} xl={3} xxl={3} key={i}>
                        <div
                            style={{
                                width: "64px",
                                marginBottom: "20px",
                                textAlign: "center",
                            }}
                        >
                            {" "}
                            <Link to={"/product?s=" + item.actor}>
                                <Badge count={item.num}>
                                    <Avatar
                                        size={{
                                            xs: 64,
                                            sm: 64,
                                            md: 64,
                                            lg: 64,
                                            xl: 64,
                                            xxl: 64,
                                        }}
                                        src={decodeURIComponent(item.img)}
                                    />
                                </Badge>
                                <span style={{ color: "black" }}>{item.actor}</span>
                            </Link>
                        </div>
                    </Col>
                )
            );

            return (
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                        <Row>{listItem}</Row>
                    </Col>
                </Row>
            );
        }
        const divElements = [];
        for (let i = 0; i < 24; i++) {
            divElements.push(
                <Col xs={8} sm={8} md={8} lg={3} xl={3} xxl={3} key={i}>
                    <div
                        style={{
                            width: "64px",
                            marginBottom: "20px",
                            textAlign: "center",
                        }}
                    >
                        {" "}
                        <Skeleton.Avatar key={i} active={true} size={64} shape={"circle"} />
                        <a></a>
                    </div>
                </Col>
            );
        }

        return (
            <div id="actorPage" className={isMobile == false ? "" : "actorMobile"}>
                <div className="tabPane-title" style={{ marginBottom: "20px" }}>
                    <h2>演员列表</h2>
                </div>
                {this.state.actorList != null && this.state.actorList.actor != null ? (
                    <ListItems items={this.state.actorList.actor} />
                ) : (
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
                            <Row>{divElements}</Row>
                        </Col>
                    </Row>
                )}
                {this.state.total != 0 ? (
                    <ReactPaginate
                        nextLabel="下一页"
                        onPageChange={this.handlePageClick.bind(this)}
                        pageRangeDisplayed={isMobile == true ? 1 : 7}
                        marginPagesDisplayed={isMobile == true ? 0 : 2}
                        pageCount={this.state.total}
                        previousLabel="上一页"
                        pageClassName="page-item"
                        pageLinkClassName="page-link"
                        previousClassName="page-item"
                        previousLinkClassName="page-link"
                        nextClassName="page-item"
                        nextLinkClassName="page-link"
                        breakLabel="..."
                        breakClassName="page-item"
                        breakLinkClassName="page-link"
                        containerClassName="pagination"
                        activeClassName="active"
                        renderOnZeroPageCount={undefined}
                    />
                ) : null}
            </div>
        );
    }
}

export default Actor;

