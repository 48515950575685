import { Avatar, Button, Comment, Form, Input, List, message } from "antd";  
import axios from "axios";  
import { decode, encode } from "js-base64";  
import md5 from "md5";  
import moment from "moment";  
import qs from "qs";  
import React, { useEffect, useRef, useState } from "react";  
import { Cookies, useCookies } from "react-cookie";  
import momentZone from "moment-timezone";  
import { MessageOutlined, LikeOutlined } from "@ant-design/icons";  
import guestAvatar from "./assets/avatar.png";  
const { TextArea } = Input;  
  
interface CommentItem {  
  reply: any;  
  author: string;  
  avatar: string;  
  content: React.ReactNode;  
  datetime: string;  
  likes: string;  
  dislike: string;  
}  
  
interface EditorProps {  
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;  
  onSubmit: () => void;  
  submitting: boolean;  
  value: string;  
  setIsMyInputFocused: (e: boolean) => void;  
  isMyInputFocused: boolean;  
}  
  
const CommentList = ({ comments }: { comments: CommentItem[] }) => (  
  <List  
    dataSource={comments}  
    header={`${comments.length} ${comments.length > 1 ? "replies" : "reply"}`}  
    itemLayout="horizontal"  
    renderItem={(props) => <Comment {...props} />}  
  />  
);  
const isMobile = window.innerWidth <= 820;  
  
const Editor = ({  
  onChange,  
  onSubmit,  
  submitting,  
  value,  
  setIsMyInputFocused,  
  isMyInputFocused,  
}: EditorProps) => (  
  <>  
    <Form.Item style={isMobile == false ? {} : { padding: "0 10px" }}>  
      <TextArea  
        rows={2}  
        onChange={onChange}  
        value={value}  
        onBlur={() => setIsMyInputFocused(true)}  
        onFocus={() => setIsMyInputFocused(true)}  
      />  
    </Form.Item>  
    <Form.Item  
      style={  
        isMobile == true  
          ? { position: "absolute", bottom: "7px", right: "15px" }  
          : {}  
      }  
    >  
      {isMyInputFocused == true ? ( //&& value.length > 0//  
        <Button  
          htmlType="submit"  
          loading={submitting}  
          onClick={onSubmit}  
          type="primary"  
        >  
          发布评论  
        </Button>  
      ) : null}  
    </Form.Item>  
  </>  
);  
  
interface videoInfo {  
  videoId: any;  
  signGet: any;  
}  
  
const CommentArea: React.FC<videoInfo> = (props) => {  
  const [isMyInputFocused, setIsMyInputFocused] = useState(true);  
  const [comments, setComments] = useState<CommentItem[]>([]);  
  const [submitting, setSubmitting] = useState(false);  
  const [value, setValue] = useState("");  
  const [useStatecommentType, SetUseStatecommentType] = useState<number>(0);  
  const [commentReplyValue, setCommentReplyValue] = useState<any>("");  
  const [videoId, setvideoId] = useState(props.videoId);  
  const [signGet, setsignGet] = useState(props.signGet);  
  const [commentInputId, setCommentInputId] = useState("");  
  const [commentFrom, setCommentFrom] = useState("");  
  const [commentInputBoolean, setCommentInputBoolean] = useState<any>(false);  
  const [commentHoverId, setCommentHoverId] = useState("");  
  const inputRef = useRef<any>();  
  const [usernameCookies, setUsername, removeUser] = useCookies(["username"]);  
  const [headImg, setHeadImg] = useState<any>(useCookies(["avatar"]));  
  const [openComment, setOpenComment] = useState<any>("open");  
  
  const replySubmit = () => {  
    const replayInputRefCurrent = (  
      document.getElementById("replyToComments") as HTMLInputElement  
    ).value;  
    SetUseStatecommentType(1);  
    setTimeout(() => {  
      axiosReply(replayInputRefCurrent);  
    }, 1000);  
  
    //console.log(inputRef.current) // <h1 ></h1>  
  };  
  
  const handleSubmit = () => {  
    setSubmitting(true);  
    setTimeout(() => {  
      axiosComments();  
    }, 1000);  
  };  
  
  const handleLikeComment = (id: any, i: any, actionType: string) => {  
    const cookies = new Cookies();  
    if (  
      cookies.get("username") == null ||  
      cookies.get("username") == undefined  
    ) {  
      error("请先登录");  
      return false;  
    }  
    var commentType = ["comment", "floor", "floor"];  
    const editLike = (  
      commentList: any[],  
      Id: any,  
      newLikes: any,  
      types: string  
    ) => {  
      return commentList.map((item) => {  
        var temp = Object.assign({}, item);  
        if (temp.Id === Id) {  
          if (types == "likes") {  
            temp.likes = newLikes;  
          } else {  
            temp.dislike = newLikes;  
          }  
        }  
        return temp;  
      });  
    };  
    const editFloorLike = (  
      commentList: any[],  
      Id: any,  
      newLikes: any,  
      types: string  
    ) => {  
      return commentList.map((item: any) => {  
        var temp = Object.assign({}, item);  
        console.log(temp.reply);  
        if (temp.reply[i].Id === Id) {  
          if (types == "likes") {  
            temp.reply[i].likes = newLikes;  
          } else {  
            temp.reply[i].dislike = newLikes;  
          }  
        }  
        return temp;  
      });  
    };  
  
    axios  
      .post(  
        window.apiUrl +  
          "/Application/commentFeedBack?types=" +  
          commentType[useStatecommentType] +  
          "&action=like" +  
          "&Id=" +  
          id +  
          "&sign=" +  
          signGet,  
        qs.stringify({  
          username: cookies.get("username"),  
          password: cookies.get("password"),  
        }),  
        {  
          headers: {  
            "Content-Type": "application/x-www-form-urlencoded",  
          },  
        }  
      )  
      .then((response: { data: any }) => {  
        console.log(response.data);  
        if (response.data == "success") {  
          success("已点赞");  
        } else {  
          error("你已操作过");  
        }  
        if (useStatecommentType == 0) {  
          if (actionType == "like") {  
            if (response.data == "success") {  
              const newLike = parseInt(comments[i].likes) + 1;  
              setComments(editLike(comments, id, newLike, "likes"));  
              //alert("已点赞")  
            } else {  
              const newLike = parseInt(comments[i].likes) - 1;  
              setComments(editLike(comments, id, newLike, "likes"));  
              //alert("已点赞")  
            }  
          } else {  
            if (response.data == "success") {  
              const disLike = parseInt(comments[i].dislike) + 1;  
              setComments(editLike(comments, id, disLike, "dislike"));  
              //alert("已踩")  
            } else {  
              const disLike = parseInt(comments[i].dislike) - 1;  
              setComments(editLike(comments, id, disLike, "dislike"));  
              //alert("已踩")  
            }  
          }  
        } else {  
          for (var j = 0; j < comments.length; j++) {  
            if (comments[j].reply[i].Id == id) {  
              console.log(comments[j].reply[i].likes);  
              console.log(comments[j].reply[i].dislike);  
  
              if (actionType == "like") {  
                if (response.data == "success") {  
                  const newLike = parseInt(comments[j].reply[i].likes) + 1;  
                  setComments(editFloorLike(comments, id, newLike, "likes"));  
                  //alert("已点赞")  
                } else {  
                  const newLike = parseInt(comments[j].reply[i].likes) - 1;  
                  setComments(editFloorLike(comments, id, newLike, "likes"));  
                  //alert("已点赞")  
                }  
              } else {  
                if (response.data == "success") {  
                  const disLike = parseInt(comments[j].reply[i].dislike) + 1;  
                  setComments(editFloorLike(comments, id, disLike, "dislike"));  
                  //alert("已踩")  
                } else {  
                  const disLike = parseInt(comments[j].reply[i].dislike) - 1;  
                  setComments(editFloorLike(comments, id, disLike, "dislike"));  
                  //alert("已踩")  
                }  
              }  
            }  
          }  
        }  
      })  
      .catch(function (error: any) {  
        console.log(error);  
      });  
  };  
  
  const success = (msg: string) => {  
    message.success(msg);  
  };  
  
  const error = (msg: string) => {  
    message.error(msg);  
  };  
  
  const warning = (msg: any) => {  
    message.warning(msg);  
  };  
  
  const keyHash = (key: string) => {  
    if (key == "400" || key.length == 0) {  
      return "";  
    } else {  
      var date1 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")  
        .add(1, "hours")  
        .format("YYYY-MM-DD-HH");  
      var date2 = moment(momentZone.tz("Asia/Shanghai"), "YYYY-MM-DD-HH")  
        .subtract(1, "hours")  
        .format("YYYY-MM-DD-HH");  
  
      var add = encode(md5(date1 + "sign91")).replace("=", "");  
      var subtract = encode(md5(date2 + "sign91")).replace("=", "");  
  
      var length1 = key.length;  
      var length2 = length1 - 20;  
      var content1 = key.substring(length2, length1);  
      var content2 = key.substring(0, length2);  
      ///////////////////////////////////////////  
      var keys = encode(signGet).replace("=", "");  
      let reg = new RegExp("END", "g"); //g代表全部  
      var keyHashFirst = content1.replace(reg, "");  
      var keyHash = content2 + keyHashFirst;  
      var keyResult = keyHash.replace(keys, "");  
      var keyResult1 = keyResult.replace(subtract, "");  
      var keyResult2 = keyResult1.replace(add, "");  
      return decode(keyResult2.toString());  
    }  
  };  
  
  useEffect(() => {  
    console.log("mounted");  
  
    setTimeout(() => {  
      axios  
        .get(window.apiUrl + "/Application/avatar" + "?sign=" + signGet)  
        .then((response: { data: any }) => {  
          //console.log(response.data)  
          var decode5 = keyHash(response.data);  
          let reg = new RegExp("\\+", "g"); //g代表全部  
          let newMsg = decode5.replace(reg, "  ");  
          var result = JSON.parse(newMsg);  
          const data = result;  
          console.log(data);  
        })  
        .catch(function (error: any) {  
          console.log(error);  
        });  
      axios  
        .get(  
          window.apiUrl +  
            "/Application/comments/" +  
            videoId +  
            "?sign=" +  
            signGet  
        )  
        .then((response: { data: any }) => {  
          //console.log(response.data)  
          var decode5 = keyHash(response.data);  
          let reg = new RegExp("\\+", "g"); //g代表全部  
          let newMsg = decode5.replace(reg, "  ");  
          var result = JSON.parse(newMsg);  
          const data = result;  
          console.log(data);  
          setComments(data);  
        })  
        .catch(function (error: any) {  
          console.log(error);  
        });  
    }, 3000);  
  }, []);  
  
  const CommentBox: React.FC<{  
    children?: React.ReactNode;  
    index: number;  
    data: {  
      username: string;  
      fromHeadImg: string;  
      headImg: string;  
      content: string;  
      Id: number;  
      commentid: number;  
      touser: string;  
      totouser: string;  
      addtime: string;  
      likes: string;  
      postid: string;  
    };  
  }> = ({ children, index, data }) => (  
    <Comment  
      actions={[  
        <span  
          key="comment-nested-reply-to"  
          onClick={() =>  
            data.commentid == undefined ||  
            data.commentid == null ||  
            data.commentid == 0  
              ? (setCommentInputId(data.Id.toString()),  
                SetUseStatecommentType(1),  
                setCommentFrom(data.username),  
                setCommentHoverId("0-" + data.Id),  
                setCommentInputBoolean(  
                  commentInputBoolean == false ? true : false  
                ))  
              : (setCommentInputId(data.commentid.toString()),  
                SetUseStatecommentType(2),  
                setCommentFrom(data.username),  
                setCommentHoverId("1-" + index + "-" + data.commentid),  
                setCommentInputBoolean(  
                  commentInputBoolean == false ? true : false  
                ),  
                setTimeout(() => {  
                  console.log(  
                    1 + "-" + data.commentid.toString() + "-" + commentFrom  
                  );  
                }, 200))  
          }  
        >  
          回复  
        </span>,  
        <span>{data.addtime}</span>,  
        <span  
          onTouchStart={() =>  
            data.postid != undefined || data.postid != null  
              ? SetUseStatecommentType(0)  
              : SetUseStatecommentType(1)  
          }  
          onMouseEnter={() =>  
            data.postid != undefined || data.postid != null  
              ? SetUseStatecommentType(0)  
              : SetUseStatecommentType(1)  
          }  
          onClick={() =>  
            data.postid != undefined || data.postid != null  
              ? setTimeout(() => {  
                  handleLikeComment(data.Id, index, "like");  
                }, 300)  
              : setTimeout(() => {  
                  useStatecommentType == 1  
                    ? handleLikeComment(data.Id, index, "like")  
                    : console.log();  
                }, 300)  
          }  
        >  
          <LikeOutlined />  
          {data.likes != "0" ? data.likes : ""}{" "}  
        </span>,  
      ]}  
      author={  
        data.totouser != undefined ? (  
          <div>  
            <a>{data.username}</a> 🢒 <a>{data.totouser}</a>  
          </div>  
        ) : (  
          <div>  
            <a>{data.username}</a>  
          </div>  
        )  
      }  
      avatar={  
        data.headImg != undefined && data.headImg != null ? (  
          <Avatar  
            src={  
              data.headImg.substring(0, 4) == "http"  
                ? data.headImg  
                : window.cacheUrl + "/" + data.headImg  
            }  
          />  
        ) : (  
          <Avatar  
            src={  
              data.fromHeadImg.substring(0, 4) == "http"  
                ? data.fromHeadImg  
                : window.cacheUrl + "/" + data.fromHeadImg  
            }  
          />  
        )  
      }  
      content={<p>{data.content}</p>}  
    >  
      {/**  
                 *   
                 *          
         <Comment  
          content={  
            <Editor  
              onChange={handleReplyChange}  
              onSubmit={handleSubmit}  
              submitting={submitting}  
              value={commentReplyValue}  
            />  
          }  
        />  
                 *   
                 */}  
      {(commentHoverId == "0-" + data.Id && commentInputBoolean == true) ||  
      (commentHoverId == "1-" + index + "-" + data.commentid &&  
        commentInputBoolean == true) ? (  
        <div>  
          <Input  
            id="replyToComments"  
            showCount  
            maxLength={100}  
            ref={inputRef}  
          />  
          <Button  
            type="primary"  
            onClick={() => {  
              replySubmit();  
            }}  
          >  
            Submit  
          </Button>  
        </div>  
      ) : null}  
  
      {children}  
    </Comment>  
  );  
  
  function CommentDiv(props: { data: any }) {  
    const data = props.data;  
    const listItem = data.map(  
      (  
        item: {  
          username: string;  
          fromHeadImg: string;  
          headImg: string;  
          content: string;  
          commentid: number;  
          totouser: string;  
          touser: string;  
          addtime: string;  
          Id: number;  
          likes: string;  
          postid: string;  
          reply: Iterable<unknown> | ArrayLike<unknown>;  
        },  
        i: any  
      ) => (  
        <CommentBox data={item} key={i + "-parent"} index={i}>  
          {item.reply != undefined ? (  
            <CommentDivComment list={item.reply} />  
          ) : (  
            <div></div>  
          )}  
        </CommentBox>  
      )  
    );  
    return <div className="commentParent">{listItem}</div>;  
  }  
  function CommentDivComment(props: { list: any }) {  
    const data = props.list;  
    const listItem = data.map(  
      (  
        item: {  
          username: string;  
          fromHeadImg: string;  
          headImg: string;  
          content: string;  
          commentid: number;  
          totouser: string;  
          touser: string;  
          Id: number;  
          addtime: string;  
          likes: string;  
          postid: string;  
        },  
        i: any  
      ) => <CommentBox data={item} key={i + "-child"} index={i}></CommentBox>  
    );  
    return <div className="commentChildren">{listItem}</div>;  
  }  
  
  const axiosReply = (text: any) => {  
    const cookies = new Cookies();  
    if (  
      cookies.get("username") == null ||  
      cookies.get("username") == undefined  
    ) {  
      error("请先登录");  
      setSubmitting(false);  
      return false;  
    }  
    if (useStatecommentType == 1 || useStatecommentType == 2) {  
      console.log("回复");  
      if (text == "" || text == null) {  
        error("还未填写评论内容");  
        setSubmitting(false);  
        return false;  
      }  
    }  
    const url2 =  
      window.apiUrl +  
      "/Application/floors_action/" +  
      commentInputId +  
      "?sign=" +  
      signGet;  
    var url = "";  
    var postQs = {};  
    url = url2;  
    postQs = {  
      username: cookies.get("username"),  
      password: cookies.get("password"),  
      content: text,  
      postid: videoId,  
      fromuser: commentFrom,  
    };  
    setTimeout(() => {  
      axios  
        .post(url, qs.stringify(postQs), {  
          headers: {  
            "Content-Type": "application/x-www-form-urlencoded",  
          },  
        })  
        .then((response: { data: any }) => {  
          if (response.data.msg != "发布成功！管理员审核中！") {  
            error(response.data.msg);  
            setTimeout(() => {  
              setSubmitting(false);  
            }, 100);  
          } else {  
            success("评论已发送，审核通过后即可显示");  
            setTimeout(() => {  
              setValue("");  
              setCommentReplyValue("");  
              setSubmitting(false);  
              setCommentInputBoolean(false);  
            }, 100);  
          }  
  
          return false;  
        })  
        .catch((error: any) => {  
          error("评论内含有特殊字符，请修改后再提交");  
          setTimeout(() => {  
            setSubmitting(false);  
          }, 100);  
        });  
    }, 200);  
  };  
  
  const axiosComments = () => {  
    const cookies = new Cookies();  
    if (  
      cookies.get("username") == null ||  
      cookies.get("username") == undefined  
    ) {  
      error("请先登录");  
      setSubmitting(false);  
      return false;  
    }  
    if (useStatecommentType == 0) {  
      console.log("评论");  
      if (value == "" || value == null) {  
        error("还未填写评论内容");  
        setSubmitting(false);  
        return false;  
      }  
    }  
    if (useStatecommentType == 1 || useStatecommentType == 2) {  
      console.log("回复");  
      if (commentReplyValue == "" || commentReplyValue == null) {  
        error("还未填写评论内容");  
        setSubmitting(false);  
        return false;  
      }  
    }  
    const url1 =  
      window.apiUrl +  
      "/Application/comment_action/" +  
      videoId +  
      "?sign=" +  
      signGet;  
    const url2 =  
      window.apiUrl +  
      "/Application/floors_action/" +  
      commentInputId +  
      "?sign=" +  
      signGet;  
    var url = "";  
    var postQs = {};  
    if (useStatecommentType == 0) {  
      url = url1;  
      postQs = {  
        username: cookies.get("username"),  
        password: cookies.get("password"),  
        content: value,  
      };  
    } else {  
      url = url2;  
      postQs = {  
        username: cookies.get("username"),  
        password: cookies.get("password"),  
        content: commentReplyValue,  
        postid: videoId,  
        fromuser: commentFrom,  
      };  
    }  
    setTimeout(() => {  
      axios  
        .post(url, qs.stringify(postQs), {  
          headers: {  
            "Content-Type": "application/x-www-form-urlencoded",  
          },  
        })  
        .then((response: { data: any }) => {  
          if (response.data.msg != "发布成功！管理员审核中！") {  
            error(response.data.msg);  
            setTimeout(() => {  
              setSubmitting(false);  
            }, 100);  
          } else {  
            success("评论已发送，审核通过后即可显示");  
            setTimeout(() => {  
              setValue("");  
              setCommentReplyValue("");  
              setSubmitting(false);  
              setCommentInputBoolean(false);  
            }, 100);  
          }  
  
          return false;  
        })  
        .catch((error: any) => {  
          error("评论内含有特殊字符，请修改后再提交");  
          setTimeout(() => {  
            setSubmitting(false);  
          }, 100);  
        });  
    }, 200);  
  };  
  
  let handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {  
    console.log(e.target.value);  
    setValue(e.target.value);  
  };  
  
  let setIsMyInputFocusedState = (bool: any) => {  
    setIsMyInputFocused(bool);  
  };  
  
  return (  
    <>  
      <div className="commentsArea">  
        <div  
          className="commentsTitle"  
          style={isMobile == false ? {} : { padding: "0 10px" }}  
        >  
          {isMobile == false ? (  
            <h4>  
              <MessageOutlined />  
              评论区  
            </h4>  
          ) : null}  
        </div>  
        <div onClick={() => {}}></div>  
        {isMobile == true ? (  
          <div className="replyArea">  
            {comments.length > 0 && comments[0].content != null ? (  
              <CommentDiv data={comments} />  
            ) : null}  
          </div>  
        ) : null}  
        <Comment  
          avatar={<Avatar src={guestAvatar} />}  
          content={  
            <Editor  
              onChange={handleChange}  
              onSubmit={handleSubmit}  
              submitting={submitting}  
              value={value}  
              setIsMyInputFocused={setIsMyInputFocusedState}  
              isMyInputFocused={isMyInputFocused}  
            />  
          }  
        />  
        {/**  {comments.length > 0 && <CommentList comments={comments} />}*/}  
  
        {/**  {comments.length > 0 && <CommentList comments={comments} />}*/}  
        {isMobile == false ? (  
          <div className="replyArea">  
            {comments.length > 0 && comments[0].content != null ? (  
              <CommentDiv data={comments} />  
            ) : null}  
          </div>  
        ) : null}  
      </div>  
    </>  
  );  
};  
  
export default CommentArea;  

